import axios from '@/api/axios'

let urls = {
  submitData: 'https://api.2gdt.com/fm-manage/admin/contactUs/create' // 提交信息
}

function apiPromise(options) {
  let opt = Object.assign({
    method: 'post',
    data: {},
    url: ''
  }, options || {})

  return new Promise((resolve, reject) => {
    axios[opt.method](opt.url, opt.data).then((resp) => {
      resolve(resp)
    }).catch((error) => {
      reject(error)
    })
  })
}

export default {
  getQueryString(name) {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i'), val;
    let r = window.location.search.substr(1).match(reg);
    r ? val = decodeURIComponent(r[2]) : val = null;
    return val;
  },
  submitData: (data) => {
    let options = {
      url: urls.submitData,
      data: data
    }
    return apiPromise(options)
  }
}

function param(data) {
  let url = ''
  for (let k in data) {
    let value = data[k] !== undefined ? data[k] : ''
    url += '&' + k + '=' + encodeURIComponent(value)
  }
  return url ? url.substring(1) : ''
}
